// -------------------------- Margin ---------------------------- //

$margin-xs: 10px;
$margin-sm: 12px;
$margin-md: 12px;
$margin-lg: 12px;
$margin-xl: 20px;

$margin-menu-card: 4px;

$margin-extra-tiny: 3px;
$margin-tiny: 5px;
$margin-moderate: 10px;
$margin-jumbo: 15px;
$margin-extra-jumbo: 20px;

$margin-dashboard-totals-top-lg: 20px;
$margin-dashboard-totals-top-xl: 20px;

$margin-dashboard-totals-bottom-lg: 5px;
$margin-dashboard-totals-bottom-xl: 10px;

$margin-dashboard-details-top-lg: 5px;
$margin-dashboard-details-top-xl: 10px;

$margin-dashboard-details-bottom-lg: 20px;
$margin-dashboard-details-bottom-xl: 20px;

$margin-menu-right: 8px;
$margin-card-details-left: 8px;

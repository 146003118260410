// -------------------------- Padding ---------------------------- //

$padding-xs: 10px;
$padding-sm: 20px;
$padding-md: 25px;
$padding-lg: 30px;
$padding-xl: 40px;

$padding-menu-item-inner: 5px;
$padding-menu-item-outer: 10px;

$padding-extra-tiny: 3px;
$padding-tiny: 5px;
$padding-moderate: 10px;
$padding-jumbo: 15px;
$padding-extra-jumbo: 20px;

// Heading
$padding-header-balance-bottom-md: 24px;

// Payout-request-form-dialog
$padding-payout-request-dialog-horizontal-inner-xs: 4px;
$padding-payout-request-dialog-horizontal-inner-sm: 10px;
$padding-payout-request-dialog-horizontal-inner-md: 64px;
$padding-payout-request-dialog-horizontal-inner-lg: 64px;
$padding-payout-request-dialog-horizontal-inner-xl: 64px;  

$padding-payout-request-dialog-horizontal-outer-xs: 10px;
$padding-payout-request-dialog-horizontal-outer-sm: 20px;
$padding-payout-request-dialog-horizontal-outer-md: 48px;
$padding-payout-request-dialog-horizontal-outer-lg: 48px;
$padding-payout-request-dialog-horizontal-outer-xl: 48px;

$padding-payout-request-dialog-vertical-outer-xs: 24px;
$padding-payout-request-dialog-vertical-outer-sm: 24px;
$padding-payout-request-dialog-vertical-outer-md: 48px;
$padding-payout-request-dialog-vertical-outer-lg: 48px;
$padding-payout-request-dialog-vertical-outer-xl: 48px;

// Referral-form-dialog
$padding-referral-form-dialog-horizontal-outer-xs: 20px;
$padding-referral-form-dialog-horizontal-outer-sm: 25px;
$padding-referral-form-dialog-horizontal-outer-md: 48px;
$padding-referral-form-dialog-horizontal-outer-lg: 48px;
$padding-referral-form-dialog-horizontal-outer-xl: 48px;

$padding-referral-form-dialog-vertical-outer-xs: 20px;
$padding-referral-form-dialog-vertical-outer-sm: 15px;
$padding-referral-form-dialog-vertical-outer-md: 48px;
$padding-referral-form-dialog-vertical-outer-lg: 48px;
$padding-referral-form-dialog-vertical-outer-xl: 48px;

// ------------------------------- Buttons ---------------------------------- //

// submit button
$width-button-submit-xs: 110px;
$width-button-submit-sm-md-lg-xl: 150px;

$height-button-submit-xs: 36px;
$height-button-submit-sm-md-lg-xl: 48px;

// back button
$width-button-back-xs: 80px;
$width-button-back-sm: 120px;
$width-button-back-md-lg-xl: 150px;

$height-button-back-xs: 36px;
$height-button-back-sm-md-lg-xl: 48px;

// close button
$width-button-close-xs: 198px;
$width-button-close-sm: 303px;
$width-button-close-md-lg-xl: 320px;

$height-button-close-xs: 36px;
$height-button-close-sm-md-lg-xl: 48px;

// download button
$width-button-download-xs: 80px;
$width-button-download-sm-md-lg-xl: 212px;

$height-button-download-xs: 18px;
$height-button-download-sm-md-lg-xl: 36px;

// other buttons
$width-button-extra-narrow: 35px;
$width-button-narrow: 70px;
$width-button-medium: 90px;
$width-button-wide: 125px;
$width-button-wide-plus-spinner: calc(#{$width-button-wide} + 18px + #{$margin-tiny}); // 18px - spinner diameter
$width-button-icon: 25px;

$height-button-low: 30px;
$height-button-medium-high: 35px;
$height-button-high: 40px;
$height-button-icon: 25px;

// -------------------------- Select positioning ---------------------------- //

// $margin-top-header-wallet-select-xs: $height-header-heading-xs;
// $margin-left-header-wallet-select-xs: $margin-xs;

// $margin-top-header-wallet-select-sm: $height-header-heading-sm;
// $margin-left-header-wallet-select-sm: calc((100vw - #{$width-header-wallet-select-sm}) / 2);

// $margin-top-header-wallet-select-md-lg-xl: -10px;

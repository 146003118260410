@import '../utilities/variables';

* {
	font-family: $my-font-plain-regular !important;
	box-sizing: border-box !important;
}

html {
	font-size: 16px;
}

body {
	font-size: 0.875rem;
	color: $black;
	background-color: $white;

	width: 100vw !important;
	min-width: 100vw !important;
	max-width: 100vw !important;

	height: 100vh !important;
	height: calc(var(--vh, 1vh) * 100) !important; // mobile viewport bug fix
	height: -webkit-fill-available !important;
	min-height: 100vh !important;
	min-height: calc(var(--vh, 1vh) * 100) !important; // mobile viewport bug fix
	min-height: -webkit-fill-available !important;
	max-height: 100vh !important;
	max-height: calc(var(--vh, 1vh) * 100) !important; // mobile viewport bug fix
	max-height: -webkit-fill-available !important;
}

.app-root {
	display: flex !important;
	flex-direction: column !important;
	justify-content: center !important;
	align-items: center !important;
}

.link {
	text-decoration: underline;
	cursor: pointer;
}

.link_on-light {
	color: $blue-dark;
}

.link_on-dark {
	color: $blue-light;
}

h1,
h2,
h3,
h4 {
	margin: 0;
}

h1 {
	font-weight: 800;
	line-height: 1.25;
}

h2 {
	font-weight: 600;
	line-height: 1.25;
}

h3 {
	font-weight: 600;
	line-height: 1.25;
}

h4 {
	font-weight: 500;
	line-height: 1.25;
}

p {
	padding: 0.25rem 0;
}

a {
	color: currentColor;
	font-weight: 500;
	text-decoration: none;
}

.isSpinnerVisibleAtDashboard {
	display: flex;
	margin: 1px; // without it text (ex PURCHASES or REFERRALS) is dancing when spinner appears
	padding: 5px 0;
}

.isSpinnerVisibleAtMenuItem {
	display: flex;
	justify-content: center;
	align-items: center;

	width: 100%;
	border-top: 1px solid $gray-dark;
	background-color: $gray-light;
}

.isSpinnerVisibleAtEmailCheck {
	display: flex;
	// margin: 1px;
	padding: 0;
}

.status {
  padding: 0 $padding-tiny;
}

.status.success {
  background-color: $success-light;
  color: $success-dark;
}
.status.pending {
  background-color: $pending-light;
  color: $pending-dark;
}
.status.cancelled {
  background-color: $error-light;
  color: $error-dark;
}
.status.failed {
  background-color: $error-light;
  color: $error-dark;
}
.status.error {
  background-color: $error-light;
  color: $error-dark;
}

// --------------------------------- XS -------------------------------------
@media screen and (min-width: $breakpoint-xs-min-width) and (max-width: $breakpoint-xs-max-width) {
	h1 {
		font-size: $font-size-h1-xs;
	}

	h2 {
		font-size: $font-size-h2-xs;
	}

	h3 {
		padding-left: $padding-moderate;
		font-size: $font-size-h3-xs;
	}

	h4 {
		font-size: $font-size-h4-xs;
	}

	p,
	div,
	th,
	td {
		font-size: $font-size-default-xs !important;
	}

	th,
	td {
		padding: $padding-moderate !important;
	}

	.isSpinnerVisibleAtMenuItem {
		height: calc(#{$min-height-details-xs} - #{$height-details-heading-xs});
	}

  .status {
    font-size: 10px !important;
  }

	// .app-version {
	// 	position: relative;
	// 	left: 6px;
	// 	top: 1px;
	// }
}

// --------------------------------- SM -------------------------------------
@media screen and (min-width: $breakpoint-sm-min-width) and (max-width: $breakpoint-sm-max-width) {
	h1 {
		font-size: $font-size-h1-sm;
	}

	h2 {
		font-size: $font-size-h2-sm;
	}

	h3 {
		padding-left: $padding-moderate;
		font-size: $font-size-h3-sm;
	}

	h4 {
		font-size: $font-size-h4-sm;
	}

	p,
	div,
	th,
	td {
		font-size: $font-size-default-sm !important;
	}

	th,
	td {
		padding: $padding-moderate !important;
	}

	.isSpinnerVisibleAtMenuItem {
		height: calc(#{$min-height-details-sm} - #{$height-details-heading-sm});
	}

  .status {
    font-size: 10px !important;
  }

	// .app-version {
	// 	position: relative;
	// 	left: 6px;
	// 	top: -1px;
	// }
}

// --------------------------------- MD -------------------------------------
@media screen and (min-width: $breakpoint-md-min-width) and (max-width: $breakpoint-md-max-width) {
	h1 {
		font-size: $font-size-h1-md;
	}

	h2 {
		font-size: $font-size-h2-md;
	}

	h3 {
		padding-left: $padding-moderate;
		font-size: $font-size-h3-md;
	}

	h4 {
		font-size: $font-size-h4-md;
	}

	p,
	div,
	th,
	td {
		font-size: $font-size-default-md !important;
	}

	th,
	td {
		padding: $padding-jumbo !important;
	}

	.isSpinnerVisibleAtMenuItem {
		height: calc(#{$min-height-details-md} - #{$height-details-heading-md});
	}

  .status {
    font-size: 12px !important;
  }

	// .app-version {
	// 	position: relative;
	// 	left: 6px;
	// 	top: -5px;
	// }
}

// --------------------------------- LG -------------------------------------
@media screen and (min-width: $breakpoint-lg-min-width) and (max-width: $breakpoint-lg-max-width) {
	h1 {
		font-size: $font-size-h1-lg;
	}

	h2 {
		font-size: $font-size-h2-lg;
	}

	h3 {
		padding-left: $padding-jumbo;
		font-size: $font-size-h3-lg;
	}

	h4 {
		font-size: $font-size-h4-lg;
	}

	p,
	div,
	th,
	td {
		font-size: $font-size-default-lg !important;
	}

	th,
	td {
		padding: $padding-extra-jumbo !important;
	}

	.isSpinnerVisibleAtMenuItem {
		height: calc(#{$min-height-details-lg} - #{$height-details-heading-lg});
	}

  .status {
    font-size: 12px !important;
  }

	// .app-version {
	// 	position: relative;
	// 	left: 6px;
	// 	top: -4px;
	// }
}

// --------------------------------- XL -------------------------------------
@media screen and (min-width: $breakpoint-xl-min-width) {
	h1 {
		font-size: $font-size-h1-xl;
	}

	h2 {
		font-size: $font-size-h2-xl;
	}

	h3 {
		padding-left: $padding-jumbo;
		font-size: $font-size-h3-xl;
	}

	h4 {
		font-size: $font-size-h4-xl;
	}

	p,
	div,
	th,
	td {
		font-size: $font-size-default-xl !important;
	}

	th,
	td {
		padding: $padding-extra-jumbo !important;
	}

	.isSpinnerVisibleAtMenuItem {
		height: calc(#{$min-height-details-xl} - #{$height-details-heading-xl});
	}

  .status {
    font-size: 12px !important;
  }

	// .app-version {
	// 	position: relative;
	// 	left: 5px;
	// 	top: -5px;
	// }
}

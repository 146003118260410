// ----------------------- Fonts ----------------------- //

$my-font-plain-regular: 'plain-regular', Arial, sans-serif !default;
$my-font-plain-medium: 'plain-medium', Arial, sans-serif !default;
$my-font-plain-bold: 'plain-bold', Arial, sans-serif !default;
$my-font-sans: Roboto, Arial, sans-serif !default;
$my-font-serif: Georgia, Cambria, 'Times New Roman', Times, serif !default;
$my-font-mono: 'IBM Plex Mono', 'Courier New', monospace !default;

$font-size-default-xs: 11.5px;
$font-size-default-sm: 12px;
$font-size-default-md: 12.5px;
$font-size-default-lg: 13px;
$font-size-default-xl: 13.5px;

$font-size-h1-xs: 1.8rem;
$font-size-h1-sm: 1.85rem;
$font-size-h1-md: 1.9rem;
$font-size-h1-lg: 1.95rem;
$font-size-h1-xl: 2rem;

$font-size-h2-xs: 1.4rem;
$font-size-h2-sm: 1.45rem;
$font-size-h2-md: 1.5rem;
$font-size-h2-lg: 1.55rem;
$font-size-h2-xl: 1.6rem;

$font-size-h3-xs: 0.9rem;
$font-size-h3-sm: 1rem;
$font-size-h3-md: 1.25rem;
$font-size-h3-lg: 1.35rem;
$font-size-h3-xl: 1.4rem;

$font-size-h4-xs: 0.8rem;
$font-size-h4-sm: 0.85rem;
$font-size-h4-md: 0.9rem;
$font-size-h4-lg: 0.95rem;
$font-size-h4-xl: 1rem;

$font-size-status-xs: 11px;
$font-size-status-sm: 11.5px;
$font-size-status-md: 12px;
$font-size-status-lg: 12.5px;
$font-size-status-xl: 13px;

@import '../utilities/variables';

// ----------------------------------------------------------------------------------------------------------------------------------------------------------- //
// --------------------------------------------------------------- Button ------------------------------------------------------------------------------------ //
// ----------------------------------------------------------------------------------------------------------------------------------------------------------- //

.mat-mdc-button {
	display: flex !important;
	align-items: center;
	justify-content: center;
	line-height: 1 !important;

	// to make spinners and text behave as display:flex items
	/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
	.mat-button-wrapper {
		display: flex !important;
		align-items: center;
		justify-content: center;
		height: 100%;

		padding: $padding-tiny;
	}
}

// ----------- Size -----------

.button-submit {
  // -------- XS --------
  @media screen and (min-width: $breakpoint-xs-min-width) and (max-width: $breakpoint-xs-max-width) {
    width: $width-button-submit-xs !important;
    min-width: $width-button-submit-xs !important;
    max-width: $width-button-submit-xs !important;

    height: $height-button-submit-xs !important;
    min-height: $height-button-submit-xs !important;
    max-height: $height-button-submit-xs !important;
  }

  // -------- SM-MD-LG-XL --------
  width: $width-button-submit-sm-md-lg-xl !important;
	min-width: $width-button-submit-sm-md-lg-xl !important;
	max-width: $width-button-submit-sm-md-lg-xl !important;

	height: $height-button-submit-sm-md-lg-xl !important;
	min-height: $height-button-submit-sm-md-lg-xl !important;
	max-height: $height-button-submit-sm-md-lg-xl !important;
}

.button-back {
  // -------- XS --------
  @media screen and (min-width: $breakpoint-xs-min-width) and (max-width: $breakpoint-xs-max-width) {
    width: $width-button-back-xs !important;
    min-width: $width-button-back-xs !important;
    max-width: $width-button-back-xs !important;

    height: $height-button-back-xs !important;
    min-height: $height-button-back-xs !important;
    max-height: $height-button-back-xs !important;
  }

  // -------- SM --------
  @media screen and (min-width: $breakpoint-sm-min-width) and (max-width: $breakpoint-sm-max-width) {
    width: $width-button-back-sm !important;
    min-width: $width-button-back-sm !important;
    max-width: $width-button-back-sm !important;
  }

  // -------- MD-LG-XL --------
  width: $width-button-back-md-lg-xl !important;
	min-width: $width-button-back-md-lg-xl !important;
	max-width: $width-button-back-md-lg-xl !important;

	height: $height-button-back-sm-md-lg-xl !important;
	min-height: $height-button-back-sm-md-lg-xl !important;
	max-height: $height-button-back-sm-md-lg-xl !important;
}

.button-close {
  // -------- XS --------
  @media screen and (min-width: $breakpoint-xs-min-width) and (max-width: $breakpoint-xs-max-width) {
    width: $width-button-close-xs !important;
    min-width: $width-button-close-xs !important;
    max-width: $width-button-close-xs !important;

    height: $height-button-close-xs !important;
    min-height: $height-button-close-xs !important;
    max-height: $height-button-close-xs !important;
  }

  // -------- SM --------
  @media screen and (min-width: $breakpoint-sm-min-width) and (max-width: $breakpoint-sm-max-width) {
    width: $width-button-close-sm !important;
    min-width: $width-button-close-sm !important;
    max-width: $width-button-close-sm !important;
  }

  // -------- MD-LG-XL --------
  width: $width-button-close-md-lg-xl !important;
	min-width: $width-button-close-md-lg-xl !important;
	max-width: $width-button-close-md-lg-xl !important;

	height: $height-button-close-sm-md-lg-xl !important;
	min-height: $height-button-close-sm-md-lg-xl !important;
	max-height: $height-button-close-sm-md-lg-xl !important;
}

.button-download {
  // -------- XS --------
  @media screen and (min-width: $breakpoint-xs-min-width) and (max-width: $breakpoint-xs-max-width) {
    width: $width-button-download-xs !important;
    min-width: $width-button-download-xs !important;
    max-width: $width-button-download-xs !important;

    height: $height-button-download-xs !important;
    min-height: $height-button-download-xs !important;
    max-height: $height-button-download-xs !important;
  }

  // -------- SM-MD-LG-XL --------
  width: $width-button-download-sm-md-lg-xl !important;
	min-width: $width-button-download-sm-md-lg-xl !important;
	max-width: $width-button-download-sm-md-lg-xl !important;

	height: $height-button-download-sm-md-lg-xl !important;
	min-height: $height-button-download-sm-md-lg-xl !important;
	max-height: $height-button-download-sm-md-lg-xl !important;

  border-radius: $border-radius-round;
}

// TODO: fix this duck tape. make button with diff paddings for diff resolutions
.button-padding-extra-tiny {
	padding-left: $padding-extra-tiny !important;
	padding-right: $padding-extra-tiny !important;
}

.button-padding-tiny {
	padding-left: $padding-tiny !important;
	padding-right: $padding-tiny !important;
}

.button-full-width {
	width: 100%;
	min-width: 100%;
	max-width: 100%;
}

.button-wide-plus-spinner {
	width: $width-button-wide-plus-spinner;
	min-width: $width-button-wide-plus-spinner;
	max-width: $width-button-wide-plus-spinner;
}

.button-wide {
	width: $width-button-wide;
	min-width: $width-button-wide;
	max-width: $width-button-wide;
}

.button-wide-medium {
	width: $width-button-medium;
	min-width: $width-button-medium;
	max-width: $width-button-medium;
}

.button-narrow {
	width: $width-button-narrow !important;
	min-width: $width-button-narrow !important;
	max-width: $width-button-narrow !important;
}

.button-extra-narrow {
	width: $width-button-extra-narrow !important;
	min-width: $width-button-extra-narrow !important;
	max-width: $width-button-extra-narrow !important;
}

.button-low {
	height: $height-button-low;
	min-height: $height-button-low;
	max-height: $height-button-low;
}

.button-medium-high {
	height: $height-button-medium-high !important;
	min-height: $height-button-medium-high !important;
	max-height: $height-button-medium-high !important;
}

.button-high {
	height: $height-button-high;
	min-height: $height-button-high;
	max-height: $height-button-high;
}

.button-icon {
	width: $width-button-icon !important;
	min-width: $width-button-icon !important;
	max-width: $width-button-icon !important;

	height: $height-button-icon !important;
	min-height: $height-button-icon !important;
	max-height: $height-button-icon !important;
}

// ----------- Colors -----------

.button-green {
	color: white !important;
	background-color: $green-medium !important;
	border: $border-transparent !important;

	&:hover:not([disabled]) {
		color: white !important;
		background-color: $green-light !important;
	}

	&[disabled] {
		color: $gray-darkest !important;
		background-color: $gray-medium !important;
	}
}

.button-white {
	color: $green-medium !important;
	background-color: white !important;
	border: $border-green-medium !important;

	&:hover:not([disabled]) {
		color: $green-light !important;
		background-color: $green-lightest !important;
	}

	&[disabled] {
		color: $gray-darkest !important;
		background-color: $gray-medium !important;
	}
}

.button-white-green {
	color: $gray-darkest !important;
	background-color: white !important;
	border: $border-gray-dark !important;
  border-radius: $border-radius-round !important;

	&:hover:not([disabled]) {
		color: $gray-darkest !important;
		background-color: white !important;
	}
}

.button-main {
	color: white !important;
	background-color: $green-medium !important;
	border: $border-transparent !important;

	&:hover:not([disabled]) {
		color: $gray-light !important;
		background-color: $green-dark !important;
	}

	&[disabled] {
		color: $gray-darkest !important;
		background-color: $gray-medium !important;
	}
}

.button-secondary {
	color: $gray-dark !important;
	background-color: $black-light !important;
	border: $border-transparent !important;

	&:hover:not([disabled]) {
		color: $gray-light !important;
		background-color: $black !important;
	}
}

.button-bg-black-header {
	background-color: $black;
	color: $gray-light;

	&:hover {
		color: $gray-dark;
	}
}

.button-back {
	background-color: transparent;
	color: $gray-darkest;
}

.button-underlined {
	background-color: transparent;
	color: $black-light;
	text-decoration: underline;
}

// ----------------------------------------------------------------------------------------------------------------------------------------------------------- //
// ---------------------------------------------------------------- Card ------------------------------------------------------------------------------------- //
// ----------------------------------------------------------------------------------------------------------------------------------------------------------- //

// make bottom border of mat-card look good
.mat-mdc-card {
	background-color: $gray-light;
	border-radius: $border-radius-round !important;
	box-shadow: none !important;
}

// to make left border visible
// mat-card {
// 	margin-left: 1px;
// 	background-color: $white;
// }

// ----------------------------------------------------------------------------------------------------------------------------------------------------------- //
// --------------------------------------------------------------- Dialog ------------------------------------------------------------------------------------ //
// ----------------------------------------------------------------------------------------------------------------------------------------------------------- //

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
mat-dialog-container {
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	align-self: center;

	padding: 0 !important;

  background-color: white !important;
  box-shadow: none !important;
}

.mat-mdc-dialog-surface {
  background-color: white !important;

  box-shadow: none !important;
}

.dialogBackdropBackground {
  background-color: black !important;
  opacity: 0.7 !important;
}

// --------------------------------- XS -------------------------------------
@media screen and (min-width: $breakpoint-xs-min-width) and (max-width: $breakpoint-xs-max-width) {
	// .dialog-narrow-high-container {
	// 	min-width: $min-width-dialog-form-payout-request-xs;
	// 	width: $width-dialog-form-payout-request-xs;
	// 	max-width: $max-width-dialog-form-payout-request-xs;

	// 	min-height: $height-dialog-form-referral-xs;
	// 	height: $height-dialog-form-referral-xs;
	// 	max-height: $height-dialog-form-referral-xs;
	// }

	// .dialog-wide-short-container {
	// 	min-width: $min-width-dialog-form-referral-xs;
	// 	width: $width-dialog-form-referral-xs;
	// 	max-width: $max-width-dialog-form-referral-xs;

	// 	min-height: $height-dialog-form-buy-tagions-xs;
	// 	height: $height-dialog-form-buy-tagions-xs;
	// 	max-height: $height-dialog-form-buy-tagions-xs;
	// }
}

// --------------------------------- SM -------------------------------------
@media screen and (min-width: $breakpoint-sm-min-width) and (max-width: $breakpoint-sm-max-width) {
	// .dialog-narrow-high-container {
	// 	min-width: $min-width-dialog-form-payout-request-sm;
	// 	width: $width-dialog-form-payout-request-sm;
	// 	max-width: $max-width-dialog-form-payout-request-sm;

	// 	min-height: $height-dialog-form-referral-sm;
	// 	height: $height-dialog-form-referral-sm;
	// 	max-height: $height-dialog-form-referral-sm;
	// }

	// .dialog-wide-short-container {
	// 	min-width: $min-width-dialog-form-referral-sm;
	// 	width: $width-dialog-form-referral-sm;
	// 	max-width: $max-width-dialog-form-referral-sm;

	// 	min-height: $height-dialog-form-buy-tagions-sm;
	// 	height: $height-dialog-form-buy-tagions-sm;
	// 	max-height: $height-dialog-form-buy-tagions-sm;
	// }
}

// --------------------------------- MD -------------------------------------
@media screen and (min-width: $breakpoint-md-min-width) and (max-width: $breakpoint-md-max-width) {
	// .dialog-narrow-high-container {
	// 	min-width: $min-width-dialog-form-payout-request-md;
	// 	width: $width-dialog-form-payout-request-md;
	// 	max-width: $max-width-dialog-form-payout-request-md;

	// 	min-height: $height-dialog-form-referral-md;
	// 	height: $height-dialog-form-referral-md;
	// 	max-height: $height-dialog-form-referral-md;
	// }

	// .dialog-wide-short-container {
	// 	min-width: $min-width-dialog-form-referral-md;
	// 	width: $width-dialog-form-referral-md;
	// 	max-width: $max-width-dialog-form-referral-md;

	// 	min-height: $height-dialog-form-buy-tagions-md;
	// 	height: $height-dialog-form-buy-tagions-md;
	// 	max-height: $height-dialog-form-buy-tagions-md;
	// }
}

// --------------------------------- LG -------------------------------------
@media screen and (min-width: $breakpoint-lg-min-width) and (max-width: $breakpoint-lg-max-width) {
	// .dialog-narrow-high-container {
	// 	min-width: $min-width-dialog-form-payout-request-lg;
	// 	width: $width-dialog-form-payout-request-lg;
	// 	max-width: $max-width-dialog-form-payout-request-lg;

	// 	min-height: $height-dialog-form-referral-lg;
	// 	height: $height-dialog-form-referral-lg;
	// 	max-height: $height-dialog-form-referral-lg;
	// }

	// .dialog-wide-short-container {
	// 	min-width: $min-width-dialog-form-referral-lg;
	// 	width: $width-dialog-form-referral-lg;
	// 	max-width: $max-width-dialog-form-referral-lg;

	// 	min-height: $height-dialog-form-buy-tagions-lg;
	// 	height: $height-dialog-form-buy-tagions-lg;
	// 	max-height: $height-dialog-form-buy-tagions-lg;
	// }
}

// --------------------------------- XL -------------------------------------
@media screen and (min-width: $breakpoint-xl-min-width) {
	/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
	mat-dialog-container {
		box-sizing: border-box !important;
	}

	// .dialog-narrow-high-container {
	// 	min-width: $min-width-dialog-form-payout-request-xl;
	// 	width: $width-dialog-form-payout-request-xl;
	// 	max-width: $max-width-dialog-form-payout-request-xl;

	// 	min-height: $height-dialog-form-referral-xl;
	// 	height: $height-dialog-form-referral-xl;
	// 	max-height: $height-dialog-form-referral-xl;
	// }

	// .dialog-wide-short-container {
	// 	min-width: $min-width-dialog-form-referral-xl;
	// 	width: $width-dialog-form-referral-xl;
	// 	max-width: $max-width-dialog-form-referral-xl;

	// 	min-height: $height-dialog-form-buy-tagions-xl;
	// 	height: $height-dialog-form-buy-tagions-xl;
	// 	max-height: $height-dialog-form-buy-tagions-xl;
	// }
}

// ----------------------------------------------------------------------------------------------------------------------------------------------------------- //
// --------------------------------------------------------------- Divider ----------------------------------------------------------------------------------- //
// ----------------------------------------------------------------------------------------------------------------------------------------------------------- //

.mat-divider {
	border-top-color: $gray-dark;
}

// ----------------------------------------------------------------------------------------------------------------------------------------------------------- //
// ---------------------------------------------------------------- Form ------------------------------------------------------------------------------------- //
// ----------------------------------------------------------------------------------------------------------------------------------------------------------- //

input,
textarea {
	// remove mat-input background color filling when focus
	&:-webkit-autofill {
		transition: 'background-color 9999s ease-out';
		transition-delay: 9999s;
		-webkit-transition: 'background-color 9999s ease-out';
		-webkit-transition-delay: 9999s;
	}
}

.mat-mdc-input-element {
	height: 20px;
}

// ----------------------------------------------------------------------------------------------------------------------------------------------------------- //
// --------------------------------------------------------------- Icons ------------------------------------------------------------------------------------- //
// ----------------------------------------------------------------------------------------------------------------------------------------------------------- //

.material-icons {
	// padding: 0.25rem;
	font-family: 'Material Icons' !important;
}

.material-icons.md-18 {
	display: flex;
	align-items: center;
	font-size: 18px !important;
}
.material-icons.md-24 {
	display: flex;
	align-items: center;
	font-size: 24px !important;
}
.material-icons.md-36 {
	display: flex;
	align-items: center;
	font-size: 36px !important;
}
.material-icons.md-48 {
	display: flex;
	align-items: center;
	font-size: 48px !important;
}

/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
	color: $gray-dark;
}
.material-icons.md-dark.md-inactive {
	color: $gray-dark;
}

/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
	color: $gray-light;
}
.material-icons.md-light.md-inactive {
	color: $gray-dark;
}

/* Custom icon color */
.material-icons.md-gray-darkest {
	color: $gray-darkest;
}
.material-icons.md-gray-darkest.md-inactive {
	color: $gray-darkest;
}

.material-icons.md-black {
	color: $black-light;
}
.material-icons.md-black.md-inactive {
	color: $black-light;
}

.material-icons.md-yellow-dark {
	color: $yellow-dark !important;
}

.material-icons.md-green-dark {
	color: $success-dark !important;
}

// ----------------------------------------------------------------------------------------------------------------------------------------------------------- //
// --------------------------------------------------------------- Select ------------------------------------------------------------------------------------ //
// ----------------------------------------------------------------------------------------------------------------------------------------------------------- //

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/

.mat-mdc-select-panel {
  // need below to remove background from mat-select-panel
  background-color: $black-light !important;
  // border: none !important;
  box-shadow: none !important;
}

.wallet-select-panel-class {
  border: 1px solid $black-lighter !important;
  border-radius: $border-radius-round-6px !important;

  transform: none !important;
}

// mat-select styling
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
mat-select-trigger {
	// display: flex !important;
	// align-items: center !important;

	display: flex;
	align-items: center;

	.mat-icon {
		width: max-content;
		margin: 0;
		padding: 0 $padding-moderate;
	}
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-trigger {
	/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
	.mat-select-arrow-wrapper {
		// width: max-content !important;
		width: max-content;

		// padding-left: $padding-moderate !important;
		padding-left: $padding-moderate;
	}
}

// mat-option inside mat-select styling
.mat-mdc-option {
	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}

	.mat-icon {
		margin: 0 !important;

		width: max-content;
		padding-right: $padding-moderate;
	}

  // makes test-overflow work for wallet-select at header
  .select__wallet-name {
		white-space: nowrap !important;
		overflow: hidden !important;
		text-overflow: ellipsis !important;
	}
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
.mat-option-border-round {
	margin-bottom: $margin-extra-tiny;

	border: $border-transparent;
	border-radius: $border-radius-round;
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
.mat-option-dark-bg {
	background-color: $gray-light !important;
	color: $black-light !important;

	&:hover {
		background-color: $gray-dark !important;
		color: $black !important;
	}
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
.mat-option-dark-medium-bg {
	background-color: $gray-dark !important;
	color: $black-light !important;
	border: 1px solid $gray-darker !important;

	&:hover {
		background-color: $gray-darker !important;
		color: $black !important;
	}
}

// TODO: angular marks first of the mat-select options as mdc-list-item__primary-text. Investigate.
.mdc-list-item__primary-text {
  display: flex !important;

  min-width: 100% !important;
  width: 100% !important;
  max-width: 100% !important;

  // makes test-overflow work for referral-form select
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

// when overflow: hidden and text-overflow: ellipsis - mat-pseudo-checkbox appears at right side to '...'
.mat-pseudo-checkbox {
  display: none !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
.mat-option-white-bg {
	background-color: $black-light !important;
	color: $white !important;

	&:hover {
		background-color: $gray-darkest !important;
		color: $gray-light !important;
	}
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
.mat-option-text {
	display: flex !important;
	align-items: center !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-trigger {
	display: flex !important;
	justify-content: space-between !important;
	align-items: center !important;

	width: 100% !important;

	// padding: 0 $padding-jumbo;
	/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
	.mat-select-value {
		width: auto;
		max-width: fit-content;
	}
	/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
	.mat-select-value .mat-select-trigger-text-light-gray {
		color: $gray-light;
	}
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
	.mat-select-arrow {
		color: $gray-light;
		margin: 10px;
	}
}
// to align mat-select-value to left
.mat-mdc-select-value {
  display: flex !important;

  // equal to 90% so mat-arrow at mat-select will have 10% to be displayed
  width: 90%;
	min-width: 90%;
	max-width: 90%;
}

// --------------------------------------------------------------------------
// --------------------------------- XS -------------------------------------
// --------------------------------------------------------------------------

@media screen and (min-width: $breakpoint-xs-min-width) and (max-width: $breakpoint-xs-max-width) {
	.cdk-overlay-container .cdk-overlay-pane {
		// to reset panel-class styles
		left: 0px !important;
		top: 0px !important;
		transform: none !important;

    // to override default value of 80vw. So mat-dialog on small screens could be wider than 80vw
    max-width: 100vw !important;

		// downfall for every select
		.mat-mdc-select-panel {
			min-width: 100% !important;
			width: 100% !important;
			max-width: 100% !important;

      padding: 0 !important;
		}

		// -------------- for specific selects -------------- //

		.wallet-select-panel-class {
			position: absolute !important;
			top: calc(#{$height-header-heading-xs} + #{$height-header-wallet-select-xs} + 5px) !important;
			left: $margin-xs;

      padding: 10px 0 !important;
		}

		.referral-form-select-panel-class {
			// min-width: calc(#{$min-width-dialog-form-referral-xs} - (#{$padding-md})) !important;
			// width: calc(#{$width-dialog-form-referral-xs} - (#{$padding-md})) !important;
			// max-width: calc(#{$max-width-dialog-form-referral-xs} - (#{$padding-md})) !important;
      min-width: calc(#{$min-width-dialog-form-referral-xs} - (20px * 2)) !important;
			width: calc(#{$width-dialog-form-referral-xs} - (20px * 2)) !important;
			max-width: calc(#{$max-width-dialog-form-referral-xs} - (20px * 2)) !important;

			// 0.45 - random number to make select height look fine
			min-height: calc(#{$height-dialog-form-referral-xs} * 0.45) !important;
			height: calc(#{$height-dialog-form-referral-xs} * 0.45) !important;
			max-height: calc(#{$height-dialog-form-referral-xs} * 0.45) !important;

			position: absolute !important;
			top: calc(51.5vh - 15px) !important;
			left: calc((100vw - #{$width-dialog-form-referral-xs}) / 2 + (#{$padding-md} / 2) + 8px) !important;

			// .referral-form-option-width {
			// 	min-width: 100% !important;
			// 	width: 100% !important;
			// 	max-width: 100% !important;
			// }
		}

		// TODO: fix contact-support-form-select-panel-class

		.contact-support-form-select-panel-class {
			min-width: calc($min-width-dialog-form-contact-support-xs - (24px * 2)) !important;
			width: calc($width-dialog-form-contact-support-xs - (24px * 2)) !important;
			max-width: calc($max-width-dialog-form-contact-support-xs - (24px * 2)) !important;

			min-height: calc(#{$height-dialog-form-contact-support-xs} / 2) !important;
			height: calc(#{$height-dialog-form-contact-support-xs} / 2) !important;
			max-height: calc(#{$height-dialog-form-contact-support-xs} / 2) !important;

			position: absolute !important;
			// + 58px - height of type form field,  - 14.375px - padding of type form field
			top: calc(
				((100vh - #{$height-dialog-form-contact-support-xs}) / 2) + #{$padding-md} + #{$height-dialog-form-heading-xs} +
					58px - 14.375px
			) !important;
			left: calc((100vw - #{$width-dialog-form-contact-support-xs} + (24px * 2)) / 2) !important;

			.contact-support-form-option-width {
				min-width: 100% !important;
				width: 100% !important;
				max-width: 100% !important;
			}
		}
	}

	.mat-mdc-option {
		height: $height-header-wallet-select-xs !important;
		// padding: 0 !important;
    font-size: 12px !important;
	}
}

// --------------------------------------------------------------------------
// -------------------------- XS + small height -----------------------------
// --------------------------------------------------------------------------

@media screen and (min-width: $breakpoint-xs-min-width) and (max-width: $breakpoint-xs-max-width) and (max-height: $breakpoint-small-height) {
	.cdk-overlay-container .cdk-overlay-pane {
		.referral-form-select-panel-class {
			min-height: calc(#{$height-breakpoint-small-referral-form-dialog-card-xs} / 2) !important;
			height: calc(#{$height-breakpoint-small-referral-form-dialog-card-xs} / 2) !important;
			max-height: calc(#{$height-breakpoint-small-referral-form-dialog-card-xs} / 2) !important;

			position: absolute !important;
			top: 48vh !important;
			left: calc((100vw - #{$width-dialog-form-referral-xs}) / 2 + (#{$padding-md} / 2)) !important;
		}
	}
}

// --------------------------------------------------------------------------
// --------------------------------- SM -------------------------------------
// --------------------------------------------------------------------------

@media screen and (min-width: $breakpoint-sm-min-width) and (max-width: $breakpoint-sm-max-width) {
	.cdk-overlay-container .cdk-overlay-pane {
		// to reset panel-class styles
		left: 0px !important;
		top: 0px !important;
		transform: none !important;

    // to override default value of 80vw. So mat-dialog on small screens could be wider than 80vw
    max-width: 100vw !important;

		// downfall for every select
		.mat-mdc-select-panel {
			min-width: 0 !important;
			width: 100% !important;
			max-width: 100% !important;

      padding: 0 !important;
		}

		// -------------- for specific selects -------------- //

		.wallet-select-panel-class {
			position: absolute !important;
			top: calc(#{$height-header-heading-sm} + #{$height-header-wallet-select-sm} + 5px) !important;
			left: calc((100vw - #{$width-header-wallet-select-sm}) / 2);

      padding: 10px 0 !important;
		}

		.referral-form-select-panel-class {
			// min-width: calc(#{$min-width-dialog-form-referral-sm} - (#{$padding-md})) !important;
			// width: calc(#{$width-dialog-form-referral-sm} - (#{$padding-md})) !important;
			// max-width: calc(#{$max-width-dialog-form-referral-sm} - (#{$padding-md})) !important;
      min-width: 250px !important;
			width: 250px !important;
			max-width: 250px !important;

			// 0.45 - random number to make select height look fine
			min-height: calc(#{$height-dialog-form-referral-sm} * 0.45) !important;
			height: calc(#{$height-dialog-form-referral-sm} * 0.45) !important;
			max-height: calc(#{$height-dialog-form-referral-sm} * 0.45) !important;

			position: absolute !important;
			top: calc(52vh - 22px) !important;
			left: calc((100vw - #{$width-dialog-form-referral-sm}) / 2 + (#{$padding-md} / 2) + 13px) !important;

			// .referral-form-option-width {
			// 	min-width: 100% !important;
			// 	width: 100% !important;
			// 	max-width: 100% !important;
			// }
		}

		.contact-support-form-select-panel-class {
			min-width: calc($min-width-dialog-form-contact-support-sm - (#{$padding-md} * 2)) !important;
			width: calc($width-dialog-form-contact-support-sm - (#{$padding-md} * 2)) !important;
			max-width: calc($max-width-dialog-form-contact-support-sm - (#{$padding-md} * 2)) !important;

			min-height: calc(#{$height-dialog-form-contact-support-sm} / 2) !important;
			height: calc(#{$height-dialog-form-contact-support-sm} / 2) !important;
			max-height: calc(#{$height-dialog-form-contact-support-sm} / 2) !important;

			position: absolute !important;
			// + 59px - height of type form field,  - 15px - padding of type form field
			top: calc(
				((100vh - #{$height-dialog-form-contact-support-sm}) / 2) + #{$padding-md} + #{$height-dialog-form-heading-sm} +
					59px - 15px
			) !important;
			left: calc((100vw - #{$width-dialog-form-contact-support-sm} + (#{$padding-md} * 2)) / 2) !important;

			.contact-support-form-option-width {
				min-width: 100% !important;
				width: 100% !important;
				max-width: 100% !important;
			}
		}

		// .referral-form-option {
		// 	min-width: $min-width-dialog-form-buy-tagions-sm;
		// 	width: calc(#{$width-dialog-form-buy-tagions-sm} - (2 * #{$padding-md}));
		// 	max-width: calc(#{$width-dialog-form-buy-tagions-sm} - (2 * #{$padding-md}));
		// }
	}

	.mat-mdc-option {
		height: $height-header-wallet-select-sm !important;
		// padding: 0 !important;
    font-size: 12px !important;
	}
}

// --------------------------------------------------------------------------
// -------------------------- SM + small height -----------------------------
// --------------------------------------------------------------------------

@media screen and (min-width: $breakpoint-sm-min-width) and (max-width: $breakpoint-sm-max-width) and (max-height: $breakpoint-small-height) {
	.cdk-overlay-container .cdk-overlay-pane {
		.referral-form-select-panel-class {
			min-height: calc(#{$height-breakpoint-small-referral-form-dialog-card-sm} / 2) !important;
			height: calc(#{$height-breakpoint-small-referral-form-dialog-card-sm} / 2) !important;
			max-height: calc(#{$height-breakpoint-small-referral-form-dialog-card-sm} / 2) !important;

			position: absolute !important;
			top: 49vh !important;
			left: calc((100vw - #{$width-dialog-form-referral-sm}) / 2 + (#{$padding-md} / 2)) !important;
		}
	}
}

// --------------------------------------------------------------------------
// --------------------------------- MD -------------------------------------
// --------------------------------------------------------------------------

@media screen and (min-width: $breakpoint-md-min-width) and (max-width: $breakpoint-md-max-width) {
	.cdk-overlay-container .cdk-overlay-pane {
		// to reset panel-class styles
		left: 0px !important;
		top: 0px !important;
		transform: none !important;

		// downfall for every select
		.mat-mdc-select-panel {
			min-width: 0 !important;
			width: 100% !important;
			max-width: 100% !important;

      padding: 0 !important;
		}

		// -------------- for specific selects -------------- //

		.wallet-select-panel-class {
			position: absolute !important;
			top: calc(#{$height-header-heading-md} - 4px) !important;
			left: calc(100vw - #{$padding-md} - #{$width-header-right-block-md}) !important;

      padding: 10px 0 !important;
		}

		.referral-form-select-panel-class {
			// min-width: calc((#{$min-width-dialog-form-referral-md} - (#{$padding-md})) / 2) !important;
			// width: calc((#{$width-dialog-form-referral-md} - (#{$padding-md})) / 2) !important;
			// max-width: calc((#{$max-width-dialog-form-referral-md} - (#{$padding-md})) / 2) !important;
      min-width: 193px !important;
			width: 193px !important;
			max-width: 193px !important;

			// 0.45 - random number to make select height look fine
			min-height: calc(#{$height-dialog-form-referral-md} * 0.45) !important;
			height: calc(#{$height-dialog-form-referral-md} * 0.45) !important;
			max-height: calc(#{$height-dialog-form-referral-md} * 0.45) !important;

			position: absolute !important;
			top: 49.5vh !important;
			left: calc(
				(100vw - #{$width-dialog-form-referral-md}) / 2 + (#{$padding-md} / 2) +
					((#{$width-dialog-form-referral-md} - (#{$padding-md})) / 2 + 5px)
			) !important;

			// .referral-form-option-width {
			// 	min-width: 100% !important;
			// 	width: 100% !important;
			// 	max-width: 100% !important;
			// }
		}

		.contact-support-form-select-panel-class {
			min-width: calc($min-width-dialog-form-contact-support-md - (#{$padding-md} * 2)) !important;
			width: calc($width-dialog-form-contact-support-md - (#{$padding-md} * 2)) !important;
			max-width: calc($max-width-dialog-form-contact-support-md - (#{$padding-md} * 2)) !important;

			min-height: calc(#{$height-dialog-form-contact-support-md} / 2) !important;
			height: calc(#{$height-dialog-form-contact-support-md} / 2) !important;
			max-height: calc(#{$height-dialog-form-contact-support-md} / 2) !important;

			position: absolute !important;
			// + 59px - height of type form field,  - 15px - padding of type form field
			top: calc(
				((100vh - #{$height-dialog-form-contact-support-md}) / 2) + #{$padding-md} + #{$height-dialog-form-heading-md} +
					59px - 15px
			) !important;
			left: calc((100vw - #{$width-dialog-form-contact-support-md} + (#{$padding-md} * 2)) / 2) !important;

			.contact-support-form-option-width {
				min-width: 100% !important;
				width: 100% !important;
				max-width: 100% !important;
			}
		}
	}

	.mat-mdc-option {
		height: $height-header-wallet-select-sm !important;
		// padding: 0 !important;
	}
}

// --------------------------------------------------------------------------
// -------------------------- MD + small height -----------------------------
// --------------------------------------------------------------------------

@media screen and (min-width: $breakpoint-md-min-width) and (max-width: $breakpoint-md-max-width) and (max-height: $breakpoint-small-height) {
	.cdk-overlay-container .cdk-overlay-pane {
		.referral-form-select-panel-class {
			min-height: calc((#{$height-breakpoint-small-referral-form-dialog-card-md} / 2) - 10px) !important;
			height: calc((#{$height-breakpoint-small-referral-form-dialog-card-md} / 2) - 10px) !important;
			max-height: calc((#{$height-breakpoint-small-referral-form-dialog-card-md} / 2) - 10px) !important;

			position: absolute !important;
			top: 50vh !important;
			left: calc(
				(100vw - #{$width-dialog-form-referral-md}) / 2 + (#{$padding-md} / 2) +
					((#{$width-dialog-form-referral-md} - (#{$padding-md})) / 2)
			) !important;
		}
	}
}

// --------------------------------------------------------------------------
// --------------------------------- LG -------------------------------------
// --------------------------------------------------------------------------

@media screen and (min-width: $breakpoint-lg-min-width) and (max-width: $breakpoint-lg-max-width) {
	.cdk-overlay-container .cdk-overlay-pane {
		// to reset panel-class styles
		left: 0px !important;
		top: 0px !important;
		transform: none !important;

		// downfall for every select
		.mat-mdc-select-panel {
			min-width: 0 !important;
			width: 100% !important;
			max-width: 100% !important;

      padding: 0 !important;
		}

		// -------------- for specific selects -------------- //

		.wallet-select-panel-class {
			position: absolute !important;
			top: calc(#{$height-header-heading-lg} - 9px) !important;
			left: calc(100vw - #{$padding-lg} - #{$width-header-right-block-lg}) !important;

      padding: 10px 0 !important;
		}

		.referral-form-select-panel-class {
			// min-width: calc((#{$min-width-dialog-form-referral-lg} - (#{$padding-lg})) / 2) !important;
			// width: calc((#{$width-dialog-form-referral-lg} - (#{$padding-lg})) / 2) !important;
			// max-width: calc((#{$max-width-dialog-form-referral-lg} - (#{$padding-lg})) / 2) !important;
      min-width: 218px !important;
			width: 218px !important;
			max-width: 218px !important;

			// 0.45 - random number to make select height look fine
			min-height: calc(#{$height-dialog-form-referral-lg} * 0.45) !important;
			height: calc(#{$height-dialog-form-referral-lg} * 0.45) !important;
			max-height: calc(#{$height-dialog-form-referral-lg} * 0.45) !important;

			position: absolute !important;
			top: 50vh !important;
			left: calc(
				(100vw - #{$width-dialog-form-referral-lg}) / 2 + (#{$padding-lg} / 2) +
					((#{$width-dialog-form-referral-lg} - (#{$padding-lg})) / 2 + 5px)
			) !important;

			// .referral-form-option-width {
			// 	min-width: 100% !important;
			// 	width: 100% !important;
			// 	max-width: 100% !important;
			// }
		}

		.contact-support-form-select-panel-class {
			min-width: calc($min-width-dialog-form-contact-support-lg - (#{$padding-lg} * 2)) !important;
			width: calc($width-dialog-form-contact-support-lg - (#{$padding-lg} * 2)) !important;
			max-width: calc($max-width-dialog-form-contact-support-lg - (#{$padding-lg} * 2)) !important;

			min-height: calc(#{$height-dialog-form-contact-support-lg} / 2) !important;
			height: calc(#{$height-dialog-form-contact-support-lg} / 2) !important;
			max-height: calc(#{$height-dialog-form-contact-support-lg} / 2) !important;

			position: absolute !important;
			// + 59px - height of type form field,  - 15px - padding of type form field
			top: calc(
				((100vh - #{$height-dialog-form-contact-support-lg}) / 2) + #{$padding-lg} + #{$height-dialog-form-heading-lg} +
					59px - 15px
			) !important;
			left: calc((100vw - #{$width-dialog-form-contact-support-lg} + (#{$padding-lg} * 2)) / 2) !important;

			.contact-support-form-option-width {
				min-width: 100% !important;
				width: 100% !important;
				max-width: 100% !important;
			}
		}
	}

	.mat-mdc-option {
		height: $height-header-wallet-select-sm !important;
		// padding: 0 !important;
	}
}

// --------------------------------------------------------------------------
// -------------------------- LG + small height -----------------------------
// --------------------------------------------------------------------------

@media screen and (min-width: $breakpoint-lg-min-width) and (max-width: $breakpoint-lg-max-width) and (max-height: $breakpoint-small-height) {
	.cdk-overlay-container .cdk-overlay-pane {
		.referral-form-select-panel-class {
			min-height: calc((#{$height-breakpoint-small-referral-form-dialog-card-lg} / 2) - 10px) !important;
			height: calc((#{$height-breakpoint-small-referral-form-dialog-card-lg} / 2) - 10px) !important;
			max-height: calc((#{$height-breakpoint-small-referral-form-dialog-card-lg} / 2) - 10px) !important;

			position: absolute !important;
			top: 50vh !important;
			left: calc(
				(100vw - #{$width-dialog-form-referral-lg}) / 2 + (#{$padding-lg} / 2) +
					((#{$width-dialog-form-referral-lg} - (#{$padding-lg})) / 2)
			) !important;
		}
	}
}

// --------------------------------------------------------------------------
// --------------------------------- XL -------------------------------------
// --------------------------------------------------------------------------

@media screen and (min-width: $breakpoint-xl-min-width) {
	.cdk-overlay-container .cdk-overlay-pane {
		// downfall for every select
		.mat-mdc-select-panel {
			min-width: 0 !important;
			width: 100% !important;
			max-width: 100% !important;

      padding: 0 !important;
		}

		// -------------- for specific selects -------------- //

		.wallet-select-panel-class {
      position: absolute !important;
			top: 10px !important;
      
      padding: 10px 0 !important;
		}

		.referral-form-select-panel-class {
			min-width: calc((#{$min-width-dialog-form-referral-xl} - (#{$padding-xl})) / 2) !important;
			width: calc((#{$width-dialog-form-referral-xl} - (#{$padding-xl})) / 2) !important;
			max-width: calc((#{$max-width-dialog-form-referral-xl} - (#{$padding-xl})) / 2) !important;

			// 0.45 - random number to make select height look fine
			min-height: calc(#{$height-dialog-form-referral-xl} * 0.45) !important;
			height: calc(#{$height-dialog-form-referral-xl} * 0.45) !important;
			max-height: calc(#{$height-dialog-form-referral-xl} * 0.45) !important;

			// position: absolute !important;
			// top: 49.5vh !important;
			// left: calc(
			// 	(100vw - #{$width-dialog-form-referral-xl}) / 2 + (#{$padding-xl} / 2) +
			// 		((#{$width-dialog-form-referral-xl} - (#{$padding-xl})) / 2)
			// ) !important;

			// .referral-form-option-width {
			// 	min-width: 100% !important;
			// 	width: 100% !important;
			// 	max-width: 100% !important;
			// }
		}

		.contact-support-form-select-panel-class {
			min-width: calc($min-width-dialog-form-contact-support-xl - (#{$padding-xl} * 2)) !important;
			width: calc($width-dialog-form-contact-support-xl - (#{$padding-xl} * 2)) !important;
			max-width: calc($max-width-dialog-form-contact-support-xl - (#{$padding-xl} * 2)) !important;

			min-height: calc(#{$height-dialog-form-contact-support-xl} / 2) !important;
			height: calc(#{$height-dialog-form-contact-support-xl} / 2) !important;
			max-height: calc(#{$height-dialog-form-contact-support-xl} / 2) !important;

			position: absolute !important;
			// + 59px - height of type form field,  - 15px - padding of type form field
			top: calc(
				((100vh - #{$height-dialog-form-contact-support-xl}) / 2) + #{$padding-xl} + #{$height-dialog-form-heading-xl} +
					59px - 15px
			) !important;
			left: calc((100vw - #{$width-dialog-form-contact-support-xl} + (#{$padding-xl} * 2)) / 2) !important;

			.contact-support-form-option-width {
				min-width: 100% !important;
				width: 100% !important;
				max-width: 100% !important;
			}
		}
	}

	.mat-mdc-option {
		height: $height-header-wallet-select-sm !important;
		// padding: 0 !important;
	}
}

// --------------------------------------------------------------------------
// -------------------------- XL + small height -----------------------------
// --------------------------------------------------------------------------

@media screen and (min-width: $breakpoint-xl-min-width) and (max-height: $breakpoint-small-height) {
	.cdk-overlay-container .cdk-overlay-pane {
		.referral-form-select-panel-class {
			min-width: calc(((#{$min-width-dialog-form-referral-xl} - (#{$padding-xl})) / 2) + 5px) !important;
			width: calc(((#{$width-dialog-form-referral-xl} - (#{$padding-xl})) / 2) + 5px) !important;
			max-width: calc(((#{$max-width-dialog-form-referral-xl} - (#{$padding-xl})) / 2) + 5px) !important;

			min-height: calc((#{$height-breakpoint-small-referral-form-dialog-card-xl} / 2) - 10px) !important;
			height: calc((#{$height-breakpoint-small-referral-form-dialog-card-xl} / 2) - 10px) !important;
			max-height: calc((#{$height-breakpoint-small-referral-form-dialog-card-xl} / 2) - 10px) !important;

			position: absolute !important;
			top: 49.5vh !important;
			left: calc(
				(100vw - #{$width-dialog-form-referral-xl}) / 2 + (#{$padding-xl} / 2) +
					((#{$width-dialog-form-referral-xl} - (#{$padding-xl})) / 2)
			) !important;
		}
	}
}

// ----------------------------------------------------------------------------------------------------------------------------------------------------------- //
// -------------------------------------------------------------- Snack Bar ---------------------------------------------------------------------------------- //
// ----------------------------------------------------------------------------------------------------------------------------------------------------------- //

.snackbar-success,
.snackbar-error,
.snackbar-auth-error,
.snackbar-info {
	margin: 0 $margin-moderate $margin-jumbo $margin-moderate !important;
}

// ----------------------------------------------------------------------------------------------------------------------------------------------------------- //
// --------------------------------------------------------------- Stepper ------------------------------------------------------------------------------------ //
// ----------------------------------------------------------------------------------------------------------------------------------------------------------- //

// default mat-step css
.mat-step-icon {
  background-color: $gray-darkest !important;
}
.mat-step-label {
  color: $gray-darkest !important;
}

.mat-step-header {
  cursor: pointer !important;
}

// for active mat-step
.mat-step-header[aria-selected="true"]  {
  &:hover, &:active, &:focus {
    background-color: transparent !important;
  }

  .mat-step-icon, .mat-step-icon-active, .mat-step-icon-selected {
    background-color: black !important;
  }

  .mat-step-label, .mat-step-label-active, .mat-step-label-selected {
    color: black !important;
  }
}

// for fulfilled mat-step-icons
.mat-step-icon-state-done {
  background-color: $green-medium !important;
}

// ----------------------------------------------------------------------------------------------------------------------------------------------------------- //
// --------------------------------------------------------------- Table ------------------------------------------------------------------------------------- //
// ----------------------------------------------------------------------------------------------------------------------------------------------------------- //

.mat-mdc-table {
	// border: 1px solid $gray-medium;

  th, td {
    border: none !important;
  }

	th {
		background-color: $gray-medium !important;
		text-transform: uppercase;
	}

	.mat-mdc-row {
		background-color: $gray-light;
	}

	.mat-mdc-footer-cell {
		background-color: $gray-medium;
	}

  // to align text in heading
  .mat-sort-header-content {
    text-align: left !important;
  }
}

.mat-mdc-footer-cell {
	font-weight: 500;
}

// ----------------------------------------------------------------------------------------------------------------------------------------------------------- //
// -------------------------------------------------------------- Tooltip ------------------------------------------------------------------------------------ //
// ----------------------------------------------------------------------------------------------------------------------------------------------------------- //

.cdk-overlay-container {
	position: absolute;
	left: 200;
}

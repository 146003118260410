// ---------------- Colors -------------------- //

$white: #ffffff;

$gray-light: #f1f2f3;
$gray-medium: #e6e8ea;
$gray-dark: #dddddd;
$gray-darker: #d6d6d6;
$gray-darkest: #9c9fa1;

$black-lighter: #333333;
$black-light: #222222;
$black: #000000;

$success-light: #d7f0e6;
$success-dark: #19be6f;

$pending-light: #f3edd1;
$pending-dark: #e5bd12;

$error-light: #f3c8c8;
$error-dark: #e83c3c;

$filter-gray-darkest: invert(64%) sepia(2%) saturate(342%) hue-rotate(161deg) brightness(97%) contrast(93%);
$filter-gray-light: invert(98%) sepia(3%) saturate(130%) hue-rotate(171deg) brightness(98%) contrast(95%);
$filter-black-light: invert(8%) sepia(6%) saturate(99%) hue-rotate(314deg) brightness(93%) contrast(86%);
$filter-white: invert(96%) sepia(8%) saturate(71%) hue-rotate(274deg) brightness(117%) contrast(100%);
$filter-green-dark: invert(71%) sepia(37%) saturate(5862%) hue-rotate(106deg) brightness(94%) contrast(80%);

$red-light: $error-light;
$red-medium: #ef4444;
$red-dark: $error-dark;

$yellow-light: $pending-light;
$yellow-medium: #f0cd92;
$yellow-dark: $pending-dark;

$green-lightest: #f3fffa;
$green-lighter: #d7f0e8;
$green-light: #2DC87D;
$green-medium: #19be6f;
$green-dark: #179C5C;

$teal-light: #7edce2;
$teal-medium: #0694a2;
$teal-dark: #036672;

$blue-light: #93c5fd;
$blue-medium: #3b82f6;
$blue-dark: #1d4ed8;

@font-face {
	font-family: plain-regular;
	src: url(../assets/fonts/plain/Plain-Regular.otf) format('opentype');
}
@font-face {
	font-family: plain-medium;
	src: url(../assets/fonts/plain/Plain-Medium.otf) format('opentype');
}
@font-face {
	font-family: plain-bold;
	src: url(../assets/fonts/plain/Plain-Bold.otf) format('opentype');
}

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

// Angular Material theming tools
@import '@angular/material/theming';

// Utilities
@import 'utilities/variables';
@import 'utilities/angular-material';

// Base Rules
@import 'base/normalize';
// @import 'base/preflight';

// Overrides
@import 'overrides/typography';
@import 'overrides/angular-material';

/* Use custom @apply directives here to inline any existing utility classes into your own custom CSS.

/**
 * .btn {
 *   @apply font-bold py-2 px-4 rounded;
 * }
 */
